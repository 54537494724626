import React, { useState } from 'react';
function SocialLinks({ showChat, setShowChat }) {
  const handleChatToggle = () => {
    setShowChat(!showChat);
    console.log(showChat);
    window.parent.postMessage({ height: !showChat ? '' : 35 }, '*');
  };
  return (
    <div
      className="d-lg-flex mt-0"
      style={{
        padding: 0,
        gap: '6px',
        display: 'flex',
        alignItems: 'center',
        width: '84%',
        justifyContent: 'flex-end',
      }}
    >
      {
        <a
          href="https://t.me/congdongxoilac"
          target="_blank"
          rel="nofollow"
          className="heading-box"
          style={{
            borderRadius: '3px',
            background: '#039BE5',
            color: '#fff',
            fontWeight: '500',
            padding: '3px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            textTransform: 'uppercase',
            fontSize: '0.9rem',
            flex: 1,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_29_476)">
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#039BE5"
              ></path>
              <path
                d="M3.6606 7.8268L11.3739 4.8528C11.7319 4.72347 12.0446 4.94014 11.9286 5.48147L11.9293 5.4808L10.6159 11.6681C10.5186 12.1068 10.2579 12.2135 9.89326 12.0068L7.89326 10.5328L6.9286 11.4621C6.82193 11.5688 6.73193 11.6588 6.52526 11.6588L6.66726 9.62347L10.3739 6.2748C10.5353 6.1328 10.3379 6.05281 10.1253 6.19414L5.5446 9.07814L3.56993 8.46214C3.14126 8.32614 3.13193 8.03347 3.6606 7.8268Z"
                fill="white"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_29_476">
                <rect width="16" height="16" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          Telegram
        </a>
      }
      {
        <a
          href="https://www.facebook.com/groups/congdongxoilac"
          target="_blank"
          rel="nofollow"
          className="heading-box"
          style={{
            borderRadius: '3px',
            background: '#1570FD',
            color: '#fff',
            fontWeight: '500',
            padding: '3px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '6px',
            fontSize: '0.9rem',
            textTransform: 'uppercase',
            flex: 1,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
          >
            <path
              d="M2.27214 13.4168H4.67839V7.00016H6.85905L7.08464 4.79443H4.67839V3.69157C4.67839 3.35737 4.72851 3.12343 4.82878 2.98975C4.92904 2.85606 5.17133 2.78923 5.55566 2.78923H7.08464V0.583496H4.87891C3.8763 0.583496 3.19119 0.821612 2.82357 1.29785C2.45594 1.77409 2.27214 2.50515 2.27214 3.49105V4.79443H0.667969V7.00016H2.27214V13.4168Z"
              fill="white"
            ></path>
          </svg>
          Facebook
        </a>
      }
      <a
        className="heading-box chatbox-action"
        style={{
          background: '#141516',
          color: '#fff',
          borderRadius: '3px',
          flex: 1,
          padding: 3,
          fontWeight: '500',
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99,
          fontSize: '0.9rem',
          cursor: 'pointer',
          maxWidth: '85px',
        }}
        id="chatBoxStatus"
        onClick={handleChatToggle}
      >
        {showChat ? 'TẮT CHAT' : 'MỞ CHAT'}
      </a>
    </div>
  );
}

export default SocialLinks;
