import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Modal from 'antd-components/modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { pxToRem } from 'utils/common';
import { validation } from 'dorothy/utils/validation';
import { useApi } from 'hooks/useApi';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 10px;
    }
  }

  .confirm-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    button {
      margin-bottom: 2px;
    }
  }

  .PhoneInputInput {
    border: none;
    outline: none;
  }

  .field-group {
    .label {
      margin-bottom: ${pxToRem(5)};
    }
    margin-bottom: 15px;
  }
`;

const ReCaptchaWrapper = styled.div`
  height: 70px;
  position: relative;
  width: 100%;
  .re-captcha-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > div {
      transform: scale(0.73);
    }
  }
`;

const ModalLoginPhone = props => {
  const { visible, title, onCancel, onSuccess } = props;
  const [isShowConfirmCode, setShowConfirmCode] = useState(false);
  const { createApi: sendCode, createData: sendCodeData } = useApi(`/api/users/forgot-password`);
  const { createApi: changePassword, createData: changePasswordData } = useApi(
    `/api/users/change-password`,
  );
  const validationPhoneSchema = yup.object().shape({
    phoneNumber: validation.phoneNumberRequired,
  });
  const validationSchema = yup.object().shape({
    code: validation.required,
    password: validation.password,
    confirmPass: validation.passwordConfirmation,
  });

  const handleLoginPhone = async data => {
    if (isShowConfirmCode) {
      try {
        const res = await changePassword(data);
        if (res.success) {
          onCancel();
          onSuccess();
        }
      } catch (error) {}
      return;
    }
    try {
      const res = await sendCode(data);
      if (res.success) {
        setShowConfirmCode(true);
      }
    } catch (error) {}
  };

  const handleCapchaChange = useCallback((token, setFieldValue) => {
    setFieldValue('captchaToken', token);
  }, []);

  const handleFocus = () => {
    console.log("Chat Input focused");
    window.parent.postMessage({ focus_auth: true }, '*');
  };

  const handleBlur = () => {
    console.log("Chat Input blurred");
    window.parent.postMessage({ focus_auth: false }, '*');
  };

  const renderForm = ({ handleSubmit, setFieldValue, values, validateForm, ...form }) => {
    return (
      <Form className="form">
        {isShowConfirmCode ? (
          <>
            <div className="field-group">
              <Field 
                form={form} 
                name="code" 
                label="Mã xác nhận" 
                component={Input} 
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="field-group">
              <Field
                form={form}
                name="password"
                label="Mật khẩu"
                type="password"
                component={Input}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <Field
                form={form}
                name="confirmPass"
                label="Nhập lại mật khẩu"
                type="password"
                component={Input}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="action-box">
              <Button
                size="small"
                onClick={() => {
                  setShowConfirmCode(false);
                  setFieldValue('captchaToken', null);
                }}
              >
                Quay lại
              </Button>
              <Button
                size="small"
                htmlType="submit"
                type="primary"
                onClick={handleSubmit}
                disabled={changePasswordData.creating}
              >
                Thay đổi mật khẩu
              </Button>
            </div>
          </>
        ) : (
          <div className="field-group">
            <div className="field-group">
              <Field 
                form={form} 
                name="phoneNumber" 
                label="Số điện thoại" 
                component={Input} 
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div className="confirm-box">
              <ReCaptchaWrapper className="re-captcha-wrapper">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={token => handleCapchaChange(token, setFieldValue)}
                  className="re-captcha-box"
                />
              </ReCaptchaWrapper>
              <Button
                size="small"
                htmlType="submit"
                type="primary"
                disabled={!values.captchaToken || sendCodeData.creating}
              >
                Gửi mã xác nhận
              </Button>
            </div>
          </div>
        )}
      </Form>
    );
  };

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          validationSchema={isShowConfirmCode ? validationSchema : validationPhoneSchema}
          onSubmit={handleLoginPhone}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
};

export default ModalLoginPhone;
