import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { isEmpty, matches } from 'lodash';
import { useDispatch } from 'react-redux';
import Linkify from 'react-linkify';

import { truncateString, pxToRem } from 'utils/common';
import ModalLogin from 'components/storefront/ModalLogin';
import Notification from 'antd-components/notification';
import { MESSAGES_DELETE } from 'components/storefront/Room/ducks';

import MessageInput from './MessageInput';
import MessageList from './MessageList';
import {
  allow_sport_type_show,
  matchDetail,
  matchDetailLive,
  parseMessageMQTT,
  sport_data,
  useUser,
} from 'hooks';
import ModalBan from './ModalBan';
import { getAvatar, senderConvert } from 'utils/common';
import SocialLinks from './SocialLinks';
import ShowScores from './ShowScores';
import { getMatchLive } from 'hooks/RemoteLiveService';
import { connectMqtt, disconnectMqtt } from 'hooks/mqttService';

const OnlineLogin = styled.div`
  text-align: right;
  padding: 5px 3px;
  background: #3a3d44;
  display: flex;
  align-items: center;
  font-size: ${pxToRem(12)};
  gap: 6px;
  @media(max-width: 340px) {
    gap: 4px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const RequireLogin = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #3a3d44;
  background: linear-gradient(
    0deg,
    rgba(58, 61, 68, 0.95) 0%,
    rgba(58, 61, 68, 0.5) 65%,
    rgba(58, 61, 68, 0) 100%
  );
  color: #fff;
  text-align: center;
  padding: 30px;
  font-weight: 600;
  font-size: ${pxToRem(20)};
  text-transform: uppercase;
  cursor: pointer;
`;

const RequireLoginDesc = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  text-transform: lowercase;
  color: #fff;
`;

const ChatBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const ChatBoxDesc = styled.div`
  padding: 6px;
  text-align: center;
  background: #faca39;
  color: #333;
  font-weight: bold;
  white-space: pre-line;
  font-size: ${pxToRem(12)};
`;

const ImageCache = styled.img`
  width: 2px;
  height: 2px;
  display: none;
`;

const ChatBox = props => {
  const {
    roomId,
    room,
    messages = [],
    user = {},
    onSend,
    onLoadMore,
    isLoading,
    sportType,
    device
  } = props;
  const { limitMessageLength = 128, limitSendSpeed = 5 } = room.settings || {};

  const [showLogin, setShowLogin] = useState(false);
  const { isManager } = useUser(user);
  const [showBanModal, setShowBanModal] = useState(false);
  const [banUser, setBanUserId] = useState();
  const [isShowScores, setIsShowScores] = useState(false);
  const [isShowInBox, setIsShowInBox] = useState(false);
  const [isPopup, setIsPopup] = useState(false);

  const [match, setMatch] = useState({});
  const dispatch = useDispatch();

  const sendCountRef = useRef(0);
  const messageInputRef = useRef();
  const [showChat, setShowChat] = useState(true);

  const [onlineUsers, setOnlineUsers] = useState(0);
  const [colorsConfig, setColorsConfig] = useState({});
  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const response = await axios.get('https://api.chatboxn.com/api/room/online');
        setOnlineUsers(response.data?.online);
      } catch (error) {
        console.error('Failed to fetch online users:', error);
      }
    };

    const fetchColorsTag = async () => {
      try {
        const colors = await axios.get('https://api.chatboxn.com/api/colors');
        const { data } = colors;
        setColorsConfig(data);
      } catch (error) {
        console.error('Failed to fetch colors:', error);
      }
    };
    // fetchColorsTag();
    fetchOnlineUsers();
    const interval = setInterval(fetchOnlineUsers, 5000); // Fetch every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const handleIsShowScores = isShow => {
    setIsShowScores(isShow);
  };

  const handleSend = message => {
    let mess = message || '';
    if (!isManager) {
      if (sendCountRef.current) {
        Notification.sendMessageError(`Cảnh báo : Bạn sẽ bị ban nếu tiếp tục spam`);
        sendCountRef.current = sendCountRef.current + 1;
        return;
      }

      setTimeout(() => {
        sendCountRef.current = 0;
      }, limitSendSpeed * 1000);

      sendCountRef.current = sendCountRef.current + 1;
    }
    // const { token } = user;

    // if (!isManager) {
    //   mess = removeDomain(mess);
    // }

    onSend &&
      onSend({
        roomId,
        message: truncateString(mess, limitMessageLength),
      });
  };

  const handleShowPopup = () => {
    setIsPopup(true);
  };

  const handleLoadmore = () => {
    if (isLoading === true) {
      return;
    }

    onLoadMore && onLoadMore();
  };

  const handleMessageClick = message => {
    try {
      const { value, setValue } = messageInputRef.current || {};
      const { user } = message;
      const name = senderConvert(user.fullname || user.username);
      setValue(`${value} [${name}] `);
    } catch (error) {}
  };

  const handleShowbanModal = banUser => {
    setShowBanModal(true);
    setBanUserId(banUser);
  };

  const handleShowDeleteModal = message => {
    const messageDel = messages.find(item => item.id === message.id);
    dispatch({
      type: MESSAGES_DELETE,
      payload: {
        id: messageDel.id,
        roomId: messageDel.roomId,
        dataRaw: messageDel.dataRaw,
      },
    });
  };

  const closeModalLogin = useCallback(() => setShowLogin(false), []);
  const closeModalBan = useCallback(() => setShowBanModal(false), []);
  const renderImageCache = user => {
    if (!user) return;
    const avatar = getAvatar(user);
    return avatar ? <ImageCache src={avatar} /> : <></>;
  };

  const [matchLive, setMatchLive] = useState([]);
  const [matchScores, setMatchScores] = useState([]);
  const [matchScoreCancel, setMatchScoreCancel] = useState([]);
  const [isShowInBoxCancel, setIsShowInBoxCancel] = useState(false);
  const timeOutRemove = 30000; //30000s
  const timeoutsRef = useRef(new Map()); // To manage setTimeout references
  const timeoutsRefCancel = useRef(new Map()); // To manage setTimeout references
  useEffect(() => {
    const _getMatchLive = async () => {
      try {
        const _live = await getMatchLive('', sportType);
        const _detail_live = await matchDetailLive(sportType);
        const { data } = _live;
        const _matches = [];
        if (data.matches) {
          const teams = data.teams;
          data.matches.map(item => {
            let home_scores = item?.home_scores || [];
            let away_scores = item?.away_scores || [];
            if (_detail_live.data.results) {
              const home = _detail_live.data.results.find(
                detail => detail.id === item.home_team_id,
              );
              const away = _detail_live.data.results.find(
                detail => detail.id === item.away_team_id,
              );
              if (home) {
                home_scores = home?.score[2] || [];
              }
              if (away) {
                away_scores = away?.score[3] || [];
              }
            }
            const home_team = teams.find(team => team.id === item.home_team_id);
            const away_team = teams.find(team => team.id === item.away_team_id);
            const _item = {
              ...item,
              away_team,
              home_team,
              home_scores,
              away_scores,
              matchId: item.id,
              statusId: 4,
              homeTeam: {
                id: home_team.id,
                name: home_team.name,
              },
              awayTeam: {
                id: away_team.id,
                name: away_team.name,
              },
              homeScore: {
                regular: 2,
                ot: 0,
                penalty: 0,
              },
              awayScore: {
                regular: 0,
                ot: 0,
                penalty: 0,
              },
              isScoreCancel: Math.random() < 0.5,
              isHomeGoaled: Math.random() < 0.5,
              timeScored: '82',
            };
            _matches.push(_item);
          });
        }
        if (_matches.length) {
          // const cancel_true = _matches.filter(item => item.isScoreCancel);
          // const cancel_false = _matches.filter(item => !item.isScoreCancel);
          // setTimeout(() => {
          //   pushItemMatchScores([cancel_false[2]]);
          //   pushItemMatchScores([cancel_true[0]], true);
          // }, 2000);
          // setTimeout(() => {
          //   pushItemMatchScores([cancel_false[4]]);
          //   pushItemMatchScores([cancel_true[1]], true);
          // }, 3000);
        }
        setMatchLive(_matches);
      } catch (error) {
        // console.log('Err getMatchDetail: ', error);
        setMatchLive([]);
      }
    };

    if (sportType && allow_sport_type_show.includes(sportType)) {
      // _getMatchLive();
    }
  }, [sportType]);

  const cleanupTimeouts = () => {
    timeoutsRef.current.forEach(timeout => clearTimeout(timeout));
    timeoutsRef.current.clear();

    timeoutsRefCancel.current.forEach(timeout => clearTimeout(timeout));
    timeoutsRefCancel.current.clear();
  };

  const pushItemMatchScores = (newItems, isCancel = false) => {
    const updateScores = setScores => {
      setScores(prev => {
        const updated = [...prev];

        newItems.forEach(newItem => {
          // Kiểm tra xem phần tử đã tồn tại trong mảng hay chưa
          const existingIndex = updated.findIndex(item => item.matchId === newItem.matchId);
          if (existingIndex !== -1) {
            // Nếu phần tử đã tồn tại, reset thời gian tồn tại (xóa và thêm lại)
            clearTimeout(timeoutsRef.current.get(newItem.matchId));
            timeoutsRef.current.delete(newItem.matchId);
            updated.splice(existingIndex, 1);
          }

          updated.unshift(newItem);

          const timeout = setTimeout(() => {
            removeFromMatchScores(newItem.matchId, isCancel);
            if (isCancel) {
              timeoutsRefCancel.current.delete(newItem.matchId);
            } else {
              timeoutsRef.current.delete(newItem.matchId);
            }
          }, timeOutRemove);
          if (isCancel) {
            timeoutsRefCancel.current.set(newItem.matchId, timeout);
          } else {
            timeoutsRef.current.set(newItem.matchId, timeout);
          }
        });

        return updated;
      });
    };

    // Xử lý cho từng trường hợp
    if (isCancel) {
      updateScores(setMatchScoreCancel);
    } else {
      updateScores(setMatchScores);
    }
  };

  const removeFromMatchScores = (id, isCancel = false) => {
    const updateScores = setScores => {
      setScores(prev => prev.filter(item => item.matchId !== id));
    };

    if (isCancel) {
      updateScores(setMatchScoreCancel);
      if (timeoutsRefCancel.current.has(id)) {
        clearTimeout(timeoutsRefCancel.current.get(id));
        timeoutsRefCancel.current.delete(id);
      }
    } else {
      updateScores(setMatchScores);
      if (timeoutsRef.current.has(id)) {
        clearTimeout(timeoutsRef.current.get(id));
        timeoutsRef.current.delete(id);
      }
    }
  };

  useEffect(() => {
    console.log(`--- matchScores ${moment().format('DD/MM/yyyy hh:mm:ss')}: `, matchScores);
    setIsShowInBox(matchScores.length > 0);
  }, [matchScores]);

  useEffect(() => {
    console.log(
      `--- matchScoreCancel ${moment().format('DD/MM/yyyy hh:mm:ss')}: `,
      matchScoreCancel,
    );
    setIsShowInBoxCancel(matchScoreCancel.length > 0);
  }, [matchScoreCancel]);


  useEffect(() => {
    if (sportType && allow_sport_type_show.includes(sportType)) {
      setIsShowScores(true);
    }
  }, [sportType]);

  useEffect(() => {
    if (isShowScores) {
      const isInIframe = window.self !== window.top;
      console.log(`isInIframe ${isInIframe}`);
      const handleMqttConnection = () => {
        const _topic = sport_data[sportType].mqtt_topic || 'fb-live-score';
        // Pass updated matches to parseMessageMQTT
        connectMqtt((topic, message) => {
          if (message && message.length) {
            const cancelTrue = message.filter(item => item.isScoreCancel);
            const cancelFalse = message.filter(item => !item.isScoreCancel);
            if (cancelFalse && cancelFalse.length) {
              pushItemMatchScores(cancelFalse);
            }
            if (cancelTrue && cancelTrue.length) {
              pushItemMatchScores(cancelTrue, true);
            }
          }
        }, _topic);

        // Cleanup on unmount
        return () => {
          disconnectMqtt();
          cleanupTimeouts();
        };
      }
      if (isInIframe) {
        const handleMessage = event => {
          const { type, payload } = event.data;
          if (type === 'UPDATE_DATA') {
            // console.log(
            //   `--- ${moment().format('DD/MM/yyyy hh:mm:ss')} Message received in iframe:`,
            //   payload,
            // );
            // Xử lý dữ liệu ở đây
            if (payload && payload.length) {
              const cancelTrue = payload.filter(item => item.isScoreCancel);
              const cancelFalse = payload.filter(item => !item.isScoreCancel);
              if (cancelFalse && cancelFalse.length) {
                pushItemMatchScores(cancelFalse);
              }
              if (cancelTrue && cancelTrue.length) {
                pushItemMatchScores(cancelTrue, true);
              }
            }
          } else if (type === 'chat-iframe') {
            handleMqttConnection();
          }
        };
        // Gán listener
        window.addEventListener('message', handleMessage);

        return () => {
          // Cleanup listener
          window.removeEventListener('message', handleMessage);
        };
      } else {
        handleMqttConnection();
      }
    }
  }, [isShowScores]);

  return (
    <ChatBoxWrapper>
      <OnlineLogin>
        <SocialLinks showChat={showChat} setShowChat={setShowChat} />
        <div
          className="d-flex"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          {onlineUsers && (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00002 6.66683C9.10462 6.66683 10 5.7714 10 4.66683C10 3.56226 9.10462 2.66683 8.00002 2.66683C6.89542 2.66683 6.00002 3.56226 6.00002 4.66683C6.00002 5.7714 6.89542 6.66683 8.00002 6.66683ZM8.00002 8.00016C9.84095 8.00016 11.3334 6.50778 11.3334 4.66683C11.3334 2.82588 9.84095 1.3335 8.00002 1.3335C6.15907 1.3335 4.66669 2.82588 4.66669 4.66683C4.66669 6.50778 6.15907 8.00016 8.00002 8.00016Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10.6668C4.52724 10.6668 3.33333 11.8608 3.33333 13.3335V14.0002C3.33333 14.3684 3.03485 14.6668 2.66667 14.6668C2.29848 14.6668 2 14.3684 2 14.0002V13.3335C2 11.1244 3.79086 9.3335 6 9.3335H10C12.2091 9.3335 14 11.1244 14 13.3335V14.0002C14 14.3684 13.7015 14.6668 13.3333 14.6668C12.9651 14.6668 12.6667 14.3684 12.6667 14.0002V13.3335C12.6667 11.8608 11.4727 10.6668 10 10.6668H6Z" fill="white"/>
              </svg>
              {onlineUsers}
            </>
          )}
        </div>
        {device === 'mobile' && (
          <div
            className='chat-toggle-popup'
            onClick={() => {
              window.parent.postMessage({ isPopup: !isPopup }, '*');
              setIsPopup(!isPopup);
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            {isPopup ? (
              <svg 
                width="16" 
                height="16" 
                viewBox="0 0 16 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_10780_763)">
                  <mask 
                    id="mask0_10780_763"
                    style={{
                      maskType: 'luminance'
                    }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16" 
                    height="16"
                  >
                    <path d="M0 0H16V16H0V0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_10780_763)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.1667C5 15.6269 4.62692 16 4.16667 16L0.833333 16C0.373083 16 -5.63429e-07 15.6269 -5.23194e-07 15.1667L-2.31785e-07 11.8333C-1.9155e-07 11.3731 0.373083 11 0.833333 11C1.29358 11 1.66667 11.3731 1.66667 11.8333L1.66667 14.3333L4.16667 14.3333C4.62692 14.3333 5 14.7064 5 15.1667Z" fill="#9C9C9C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244064 15.7559C-0.0813549 15.4305 -0.0813549 14.9028 0.244065 14.5774L3.57743 11.2441C3.90285 10.9186 4.43052 10.9186 4.75594 11.2441C5.08136 11.5695 5.08136 12.0971 4.75594 12.4226L1.42257 15.7559C1.09715 16.0814 0.569484 16.0814 0.244064 15.7559Z" fill="#9C9C9C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92857 1.57143C7.63393 1.57143 6.57143 2.63395 6.57143 3.92857V7.07143C6.57143 8.36605 7.63393 9.42857 8.92857 9.42857H12.0714C13.366 9.42857 14.4286 8.36605 14.4286 7.07143V3.92857C14.4286 2.63395 13.366 1.57143 12.0714 1.57143H8.92857ZM5 3.92857C5 1.76605 6.76607 0 8.92857 0H12.0714C14.2339 0 16 1.76605 16 3.92857V7.07143C16 9.23395 14.2339 11 12.0714 11H8.92857C6.76607 11 5 9.23395 5 7.07143V3.92857Z" fill="#9C9C9C"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_10780_763">
                    <rect width="16" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            ) : (
                <svg 
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="mask0_10713_1697" 
                    style={{
                      maskType: 'luminance'
                    }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0" 
                    width="16" 
                    height="16"
                  >
                    <path d="M0 0H16V16H0V0Z" fill="white"/>
                  </mask>
                  <g mask="url(#mask0_10713_1697)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33337 3.33317C9.33337 2.96498 9.63184 2.6665 10 2.6665H12.6667C13.0349 2.6665 13.3334 2.96498 13.3334 3.33317V5.99984C13.3334 6.36802 13.0349 6.6665 12.6667 6.6665C12.2985 6.6665 12 6.36802 12 5.99984V3.99984H10C9.63184 3.99984 9.33337 3.70136 9.33337 3.33317Z" fill="#9C9C9C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1381 2.86176C13.3984 3.12212 13.3984 3.54422 13.1381 3.80458L10.4714 6.47124C10.2111 6.73157 9.78896 6.73157 9.52862 6.47124C9.26829 6.21089 9.26829 5.78878 9.52862 5.52843L12.1953 2.86176C12.4556 2.60142 12.8778 2.60142 13.1381 2.86176Z" fill="#9C9C9C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.33333C0 1.49848 1.49848 0 3.33333 0H12.6667C14.5015 0 16 1.49848 16 3.33333V12.6667C16 14.5015 14.5015 16 12.6667 16H11.2667C10.8985 16 10.6 15.7015 10.6 15.3333C10.6 14.9651 10.8985 14.6667 11.2667 14.6667H12.6667C13.7651 14.6667 14.6667 13.7651 14.6667 12.6667V3.33333C14.6667 2.23485 13.7651 1.33333 12.6667 1.33333H3.33333C2.23485 1.33333 1.33333 2.23485 1.33333 3.33333V4.73333C1.33333 5.10152 1.03485 5.4 0.666667 5.4C0.298477 5.4 0 5.10152 0 4.73333V3.33333Z" fill="#9C9C9C"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 7.99984C2.23485 7.99984 1.33333 8.90137 1.33333 9.99984V12.6665C1.33333 13.765 2.23485 14.6665 3.33333 14.6665H6C7.09847 14.6665 8 13.765 8 12.6665V9.99984C8 8.90137 7.09847 7.99984 6 7.99984H3.33333ZM0 9.99984C0 8.16497 1.49848 6.6665 3.33333 6.6665H6C7.83487 6.6665 9.33333 8.16497 9.33333 9.99984V12.6665C9.33333 14.5014 7.83487 15.9998 6 15.9998H3.33333C1.49848 15.9998 0 14.5014 0 12.6665V9.99984Z" fill="#9C9C9C"/>
                  </g>
                </svg>
              )}
          </div>
        )}
      </OnlineLogin>
      {isShowScores && (isShowInBox || isShowInBoxCancel) && (
        <div className="as-scores-main-wrapper">
          {isShowInBox && <ShowScores sportType={sportType} matchScores={matchScores} />}
          {isShowInBoxCancel && <ShowScores sportType={sportType} matchScores={matchScoreCancel} />}
        </div>
      )}
      {showChat && (
        <MessageList
          colorsConfig={colorsConfig}
          user={user}
          messages={messages}
          onYReachStart={handleLoadmore}
          loadingMore={isLoading}
          onClickMessage={handleMessageClick}
          onShowBanModal={handleShowbanModal}
          onShowDeleteModal={handleShowDeleteModal}
          marginTop={isShowScores && (isShowInBox || isShowInBoxCancel) ? '-98px' : '0px'}
        />
      )}
      {renderImageCache(user)}
      {room.isImage && room.image && showChat && !isPopup ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
          </a>
        ) : (
          <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
        )
      ) : (
        <></>
      )}
      {!room.isImage && room.description ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <ChatBoxDesc>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    className="message-linkify"
                    target="blank"
                    rel="nofollow"
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {room?.description}
              </Linkify>
            </ChatBoxDesc>
          </a>
        ) : (
          <ChatBoxDesc>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="message-linkify"
                  target="blank"
                  rel="nofollow"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {room?.description}
            </Linkify>
          </ChatBoxDesc>
        )
      ) : (
        <></>
      )}
      {showChat &&
        (!isEmpty(user) ? (
          <MessageInput
            attachButton={false}
            onSend={handleSend}
            onShowPopup={handleShowPopup}
            max={limitMessageLength}
            ref={messageInputRef}
          />
        ) : (
          <RequireLogin
            onClick={() => {
              setShowLogin(true);
              // popitup('https://www.w3schools.com');
            }}
          >
            Đăng Nhập
            <RequireLoginDesc>để tham gia bình luận</RequireLoginDesc>
          </RequireLogin>
        ))}
      <ModalLogin visible={showLogin} onCancel={closeModalLogin} roomId={roomId} />
      {showBanModal && (
        <ModalBan
          user={banUser}
          visible={showBanModal}
          title={'Cấm nhắn tin'}
          onCancel={closeModalBan}
        />
      )}
    </ChatBoxWrapper>
  );
};

export default ChatBox;
