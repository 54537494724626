import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { withCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { useInterval } from 'ahooks';
import { useLocation } from 'react-router-dom';

import { createAction } from 'dorothy/utils';
import ChatBox from './ChatBox';
import {
  ROOMS_DETAIL_REQUEST,
  MESSAGES_REQUEST,
  MESSAGES_REQUEST_SUCCESS,
  MESSAGES_NEW,
  MESSAGES_RESIZE,
} from './ducks';
import { GET_ME_REQUEST, GET_ME_RESPONSE } from 'components/dashboard/Users/ducks';
import Notification from 'antd-components/notification';
import { callApi } from 'dorothy/utils';
import { useSocket, useQuery, useOnlineTime } from 'hooks';
// import moment from 'moment';
// function randomIntFromInterval(min, max) {
//   // min and max included
//   return Math.floor(Math.random() * (max - min + 1) + min);
// }

const RoomContainer = styled.div`
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: ${props => (props.type === 'dark' ? 'rgb(0 0 0 / 85%)' : '#f8f9f9')};
  color: ${props => (props.type === 'dark' ? '#fff' : '#464749')};
  .cs-message-input__content-editor-wrapper,
  .cs-message-input__content-editor {
    background-color: ${props => (props.type === 'dark' ? '#f6f6f6' : '#fff')};
  }
  ${props =>
    props.type === 'dark'
      ? `
      .message-linkify {
        color: #d9534f;
      }
    `
      : `
      .message-input-wrapper {
        background: #f4f5f8
      }
      .message-linkify {
        color: #383838;
      }
  `};
`;

const Room = ({ storefrontRoomStore, userStore, match, dispatch, ...props }) => {
  const { cookies } = props;
  const { messages, messagesLoading, messagesLoadmore, room } = storefrontRoomStore;
  const { user = {} } = userStore;
  const userLocal = JSON.parse(localStorage.getItem('user'));
  const { params } = match;
  const pageSize = 50;
  const searchQuery = useQuery();
  const style = searchQuery.get('s') || 'dark';
  useSocket({ user, room, roomId: params?.roomId }, dispatch);
  // useOnlineTime(user, cookies, dispatch);

  const handleLoadmore = () => {
    messagesLoadmore &&
      dispatch(
        createAction(MESSAGES_REQUEST, {
          roomId: params?.roomId,
          offset: messages.length,
          pageSize,
          callBack: () => {
            setTimeout(() => {
              dispatch(createAction(MESSAGES_REQUEST_SUCCESS));
            }, 300);
          },
        }),
      );
  };

  const handleSend = async message => {
    try {
      const idTmp = uuidv4();
      const dataMessage = {
        idTmp,
        message: message.message,
        created_at: Date.now(),
        message_type: 'MESSAGE',
        roomId: message?.roomId,
        user: {
          avatar: user?.avatar,
          fullname: user?.fullname,
          id: user?.id,
          ip: user?.ip,
          level: user?.level,
          role: user?.role,
          username: user?.username,
        },
      };
      dispatch(createAction(MESSAGES_NEW, { data: [dataMessage] }));

      await callApi('POST', `/api/messages/send`, message);
      dispatch(createAction(GET_ME_RESPONSE, { ...user, messageCount: user.messageCount + 1 }));
    } catch (error) {
      // console.log('error', error);
      Notification.sendMessageError(`Cảnh báo : Bạn không thể gởi được tin nhắn`);
    }
  };

  const setWindowVh = () => {
    // Fix full height safari ios
    // The trick to viewport units on mobile
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    dispatch(createAction(GET_ME_REQUEST, { username: userLocal?.username }));
  }, [dispatch]);

  useEffect(() => {
    if (params?.roomId) {
      dispatch(createAction(ROOMS_DETAIL_REQUEST, { roomId: params?.roomId }));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (params?.roomId) {
      dispatch(
        createAction(MESSAGES_REQUEST, {
          roomId: params?.roomId,
          pageSize,
          callBack: () => {
            setTimeout(() => {
              dispatch(createAction(MESSAGES_REQUEST_SUCCESS));
            }, 300);
          },
        }),
      );
    }
  }, [params, dispatch]);

  useEffect(() => {
    setWindowVh();
    window.addEventListener('resize', setWindowVh);
  }, []);

  useInterval(() => {
    dispatch(createAction(MESSAGES_RESIZE));
  }, 60 * 60 * 1000);

  // useEffect(() => {
  //   if (!isEmpty(room)) {
  //     setInterval(() => {
  //       const rndInt = randomIntFromInterval(1, 200);
  //       if (rndInt === 50) {
  //         callApi('POST', `/api/messages/send-test`, {
  //           roomId: room.id,
  //           message: `Test message - ${moment().format('MMMM Do YYYY, HH:mm:ss a')}`,
  //         });
  //       }
  //     }, 10 * 1000);
  //   }
  // }, [room]);

  return (
    <RoomContainer type={style}>
      {!isEmpty(room) ? (
        <ChatBox
          room={room}
          roomId={params?.roomId}
          messages={messages}
          user={user}
          onLoadMore={handleLoadmore}
          onSend={handleSend}
          isLoading={messagesLoading}
          pageSize={pageSize}
          sportType={params?.sportType}
          device={params?.device}
        />
      ) : (
        <></>
      )}
    </RoomContainer>
  );
};

export default connect(({ storefrontRoom, user }) => ({
  storefrontRoomStore: storefrontRoom,
  userStore: user,
}))(withCookies(Room));
